import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import {
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base/material-icon';
import shareIOSButton from 'client/assets/share-ios-button.png';
import './styles.scss';
import { useLocalStorage } from 'client/shared/helpers/hooks';

export enum NotificationMode {
  IOS_INSTRUCTIONS = 'IOS_INSTRUCTIONS',
  INSTALL_PROMPT = 'INSTALL_PROMPT',
}

const baseClass = 'pn-custom-notification';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#EBECEB',
    width: '100%',
    padding: '1px 8px',
    display: 'flex',
    justifyContent: 'space-around',
  },
  message: {
    width: '90%',
    padding: '4px 0',
  },
  action: {
    width: '10%',
    marginRight: 'auto',
    marginLeft: '0',
    paddingLeft: 0,
  },
  iconButton: {
    padding: '2px',
  },
});

interface CustomNotificationBarProps {
  readonly mode: NotificationMode;
  readonly onInstall: () => void;
}

const CustomNotificationBar: React.FC<CustomNotificationBarProps> = ({
  mode,
  onInstall,
}) => {
  const classes = useStyles();
  const localStorage = useLocalStorage();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!localStorage?.INSTALL_NOTIFICATION_ACCEPTED);
  }, [localStorage]);

  const handleNotification = () => {
    setOpen(false);
    if (localStorage) {
      localStorage.INSTALL_NOTIFICATION_ACCEPTED = true;
    }
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      autoHideDuration={7000}
      className={`${baseClass}`}
      onClose={() => setOpen(false)}
      open={open}
    >
      <SnackbarContent
        action={[
          <IconButton
            aria-label="close"
            classes={{ root: classes.iconButton }}
            key="close"
            onClick={handleNotification}
            size="large"
          >
            <MaterialIcon
              className={`${baseClass}-close-icon`}
              icon={MaterialIconName.CLOSE_ICON}
            />
          </IconButton>,
        ]}
        classes={{
          root: classes.root,
          message: classes.message,
          action: classes.action,
        }}
        message={
          <div className={`${baseClass}-message`}>
            {conditionalRender(mode, () => {
              setOpen(false);
              onInstall();
            })}
          </div>
        }
      />
    </Snackbar>
  );
};
export default CustomNotificationBar;

function conditionalRender(mode: NotificationMode, onInstall: () => void) {
  switch (mode) {
    case NotificationMode.IOS_INSTRUCTIONS:
      return iosMessage();
    case NotificationMode.INSTALL_PROMPT:
      return installMessage({ onClick: onInstall });
  }
}

function iosMessage() {
  const copy = iosInstructionsCopy;
  return (
    <>
      <MaterialIcon
        className={`${baseClass}-circle-icon`}
        icon={MaterialIconName.ADD_CIRCLE_OUTLINE}
      />
      <div className={`${baseClass}-message-text`}>
        {copy.init}
        <img
          alt="share"
          className={`${baseClass}-share-icon`}
          src={shareIOSButton}
        />
        {copy.last}
      </div>
    </>
  );
}

function installMessage(p: { readonly onClick: () => void }) {
  const copy = installMessageCopy;
  return (
    <div
      className="d-flex"
      onClick={p.onClick}
      onKeyDown={p.onClick}
      role="button"
      tabIndex={0}
    >
      <MaterialIcon
        className={`${baseClass}-circle-icon`}
        icon={MaterialIconName.ADD_CIRCLE_OUTLINE}
      />
      <div className={`${baseClass}-message-text`}>
        <p>{copy.init}</p>
        <p>{copy.last}</p>
      </div>
    </div>
  );
}

const iosInstructionsCopy = {
  init: 'Add Polco to your Home screen: tap',
  last: 'and then Add to Home Screen',
};

const installMessageCopy = {
  init: 'Add Polco to your Home screen:',
  last: 'tap this button to install',
};
