import * as React from 'react';
import * as QueryInfos from 'client/shared/graphql-queries/query-infos';
import { useQueryInfo } from 'client/shared/containers/query';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { MainPage } from 'client/respondent/shared/pages/main-page';
import { useLanguageByQueryParam, useRedirect } from 'client/shared/hooks';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { CurrentUser } from 'client/respondent/hooks';
import { useRouteMatch } from 'react-router';

import { Overview } from '../../components/overview';
import { LoaderBars } from 'client/shared/components/loader-bars';
import { ClientQuestionId } from 'client/shared/core/question';
import { ExtractGql, QuestionSetType, ReportTabContent } from 'core';
import { nth, sortBy } from 'lodash';
import {
  ReportTabContentType,
  RespondentOverviewPage,
} from 'client/shared/graphql-client/graphql-operations.g';

import { OverviewItem, OverviewItemType } from 'client/respondent/core/types';
import { gqlToSetType } from 'client/shared/core/question-set';
import { OutcomeReport } from 'client/respondent/voting/outcome/components/report';

interface OverviewRouteSlugs {
  readonly pubSlug: string;
  readonly setSlug: string;
}

export type Props = RouteComponentProps<OverviewRouteSlugs>;

function questionSetSlugFromParamsProps(props: Props): string {
  return props.match.params.setSlug;
}

function pubSlugFromParamsProps(props: Props): string {
  return props.match.params.pubSlug;
}

export const SetOverviewPage: React.FC<Props> = (p) => {
  const redirect = useRedirect();
  const setSlug = questionSetSlugFromParamsProps(p);
  const pubSlug = pubSlugFromParamsProps(p);

  const goToFeed = () =>
    redirect(ClientUrlUtils.respondent.feed.path(), {
      push: true,
    });
  const goToQuestion = (questionId: ClientQuestionId, isLiveEvent: boolean) => {
    redirect(
      ClientUrlUtils.respondent.question.path({
        questionId,
        setIdOrSlug: setSlug,
        pubSlug: pubSlug,
        fromOverview: true,
        setType: isLiveEvent ? QuestionSetType.POLCO_LIVE : QuestionSetType.SET,
      }),
      {
        push: true,
      }
    );
  };
  const goToReport = (reportId: string) => {
    redirect(
      ClientUrlUtils.respondent.outcomeReport.path({
        setIdOrSlug: setSlug,
        pubSlug: pubSlug,
        reportId,
      }),
      {
        push: true,
      }
    );
  };
  const goToPublishingEntity = () => {
    redirect(
      ClientUrlUtils.respondent.pubProfile.path({
        slug: pubSlug,
      }),
      {
        push: true,
      }
    );
  };
  const goBack =
    p.history.length > 1
      ? p.history.goBack
      : () => {
          redirect(ClientUrlUtils.respondent.feed.path(), { push: false });
        };
  const goToOutcome = () => {
    redirect(
      ClientUrlUtils.respondent.setOutcome.path({
        setIdOrSlug: setSlug,
        pubSlug: pubSlug,
      }),
      {
        push: true,
      }
    );
  };

  const goToOverview = () => {
    redirect(
      ClientUrlUtils.respondent.setOverview.path({
        setIdOrSlug: setSlug,
        pubSlug: pubSlug,
      }),
      {
        push: true,
      }
    );
  };

  const goToSurvey = () => {
    redirect(
      ClientUrlUtils.respondent.set.path({
        setIdOrSlug: setSlug,
        pubSlug: pubSlug,
        setType: QuestionSetType.SURVEY,
      }),
      {
        push: true,
      }
    );
  };

  const reportMatch = useRouteMatch<{ readonly reportId?: string }>(
    ClientUrlUtils.respondent.outcomeReport.path({
      pubSlug: ':pubSlug?',
      setIdOrSlug: ':setSlug',
      reportId: ':reportId',
    })
  );
  const currentReportId = reportMatch?.params.reportId ?? null;

  return (
    <MainPage hideRightSidebar={!!currentReportId} hideTopNav={true}>
      {(respondentUser) => {
        return (
          <SetOverviewPageInner
            events={{
              goToFeed,
              goToPublishingEntity,
              goBack,
              goToOutcome,
              goToOverview,
              goToQuestion,
              goToReport,
              goToSurvey,
            }}
            pubSlug={pubSlug ?? 'by-id'}
            reportId={currentReportId} // not actually used, but here for clarity
            respondentUser={respondentUser}
            setSlug={setSlug}
          />
        );
      }}
    </MainPage>
  );
};

const SetOverviewPageInner: React.FC<{
  readonly respondentUser: CurrentUser | null;
  readonly pubSlug: string;
  readonly setSlug: string;
  readonly reportId: string | null;
  readonly events: {
    readonly goToFeed: () => void;
    readonly goToPublishingEntity: () => void;
    readonly goBack: () => void;
    readonly goToOutcome: () => void;
    readonly goToOverview: () => void;
    readonly goToSurvey: () => void;
    readonly goToQuestion: (
      questionId: ClientQuestionId,
      isLiveEvent: boolean
    ) => void;
    readonly goToReport: (reportId: string) => void;
  };
}> = (p) => {
  const respId = p.respondentUser?.user?.respondent?.id ?? null;
  const overviewQuery = useQueryInfo(QueryInfos.respondentOverviewPage, {
    variables: {
      questionSetSlug: p.setSlug,
      publishingEntitySlug: p.pubSlug,
      respondentId: respId,
    },
  });
  const { selectLanguageText } = useLanguageByQueryParam();

  if (overviewQuery.loading) {
    return <LoaderBars />;
  }
  if (!overviewQuery.data?.openContentSetBySlug) {
    return <Redirect to={'/res/not-found'} />;
  }
  const publishingEntity = overviewQuery.data.openContentSetBySlug.publishingEntity;

  if (!publishingEntity) {
    return <Redirect to={'/res/not-found'} />;
  }

  const setType = gqlToSetType(overviewQuery.data.openContentSetBySlug.__typename);

  const contentSet = overviewQuery.data.openContentSetBySlug;
  const overviewItems: readonly OverviewItem[] =
    contentSet.__typename === 'Survey' || contentSet.__typename === 'ContentPost'
      ? (contentSet.reportTabs ?? []).map((t) => ({
          type: OverviewItemType.REPORT,
          id: t.id,
          title: t.title,
        }))
      : contentSet.setForRespondentVote.questions.map((q) => ({
          type: OverviewItemType.QUESTION_RESULT,
          id: q.id,
          title: selectLanguageText(q.title),
        }));

  if (p.reportId && contentSet.__typename === 'Survey') {
    const { currentReport, reports } = getSortedVisibleReports(
      contentSet,
      p.reportId
    );

    if (!currentReport) {
      return <Redirect to={ClientUrlUtils.respondent.notFound.path()} />;
    }

    const curIndex = reports.indexOf(currentReport);
    const nextReportId = nth(reports, curIndex + 1)?.id ?? null;
    const previousReportId =
      curIndex - 1 < 0 ? null : nth(reports, curIndex - 1)?.id ?? null;

    return (
      <OutcomeReport
        assets={publishingEntity.assets}
        currentReport={currentReport}
        events={{
          goToReport: p.events.goToReport,
          goBack: p.events.goBack,
          goToFeed: p.events.goToFeed,
          goToPublishingEntity: p.events.goToPublishingEntity,
          goToOverview: p.events.goToOverview,
          goToSurvey: p.events.goToSurvey,
        }}
        nextReportId={nextReportId}
        previousReportId={previousReportId}
        pubName={selectLanguageText(publishingEntity.name)}
        reports={reports}
        shareable={contentSet.shareable}
        surveyTitle={selectLanguageText(contentSet.name)}
      />
    );
  } else {
    return (
      <Overview
        assets={publishingEntity.assets}
        events={{
          goToOutcome: !!contentSet.outcome ? p.events.goToOutcome : null,
          goToQuestion: p.events.goToQuestion,
          goToReport: p.events.goToReport,
          goBack: p.events.goBack,
          goToFeed: p.events.goToFeed,
          goToPublishingEntity: p.events.goToPublishingEntity,
        }}
        overviewItem={overviewItems}
        pubName={selectLanguageText(publishingEntity.name)}
        setType={setType}
        shareable={contentSet.shareable}
      />
    );
  }
};

function getSortedVisibleReports(
  contentSet: ExtractGql<
    NonNullable<RespondentOverviewPage['openContentSetBySlug']>,
    'Survey'
  >,
  reportId: string
) {
  const unsortedReports = (contentSet.reportTabs ?? []).map((t) => ({
    id: t.id,
    title: t.title,
    order: t.order,
    content:
      t.content.type === ReportTabContentType.TABLEAU
        ? ReportTabContent.TABLEAU({
            tableau_url: t.content.TABLEAU ?? '',
            height: t.content.height,
          })
        : ReportTabContent.HTML({ raw_html: t.content.HTML ?? '' }),
  }));
  const reports = sortBy(unsortedReports, (r) => r.order);

  const currentReport = reports.find((r) => r.id === reportId);
  return { currentReport, reports };
}
